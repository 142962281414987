// src/components/MainMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import './MainMenu.css'; // Archivo de estilos específico para este menú

const MainMenu = () => {
    return (
        <nav className="main-menu">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/servicios">Servicios</Link></li>
                <li><Link to="/proyectos">Proyectos</Link></li>
                <li><Link to="/contacto">Contacto</Link></li>
                <li><Link to="/login">Iniciar Sesión</Link></li>
            </ul>
        </nav>
    );
};

export default MainMenu;
