import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProyectoItem from './ProyectoItem';

const ProyectoList = ({ onEdit, onDelete }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get('http://localhost:5000/api/projects')
      .then(response => setProjects(response.data))
      .catch(error => console.error('Error fetching projects', error));
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Proyectos</h2>
      <ul>
        {projects.map(project => (
          <ProyectoItem
            key={project._id}
            project={project}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </ul>
    </div>
  );
};

export default ProyectoList;
