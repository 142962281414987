import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const GestoresMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const menuRef = useRef(null);

  const handleToggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const menuItems = [
    { path: "/gestores/panel", label: "Panel de Control" },
    { path: "/gestores/planificacion", label: "Planificación" },
    { path: "/gestores/lista", label: "Lista D & R" },
    { path: "/gestores/presupuesto", label: "Presupuesto" },
    { path: "/gestores/calidad", label: "Gestión de Calidad" },
    { path: "/gestores/seguridad", label: "Seguridad" },
    { path: "/logout", label: "Cerrar Sesión", className: "hover:bg-red-600" }
  ];

  return (
    <>
      <button 
        className="text-white bg-gray-800 p-2 rounded-md m-2 focus:outline-none focus:ring-2 focus:ring-white"
        onClick={handleToggleMenu}
        aria-label={isVisible ? "Ocultar menú" : "Mostrar menú"}
      >
        ☰
      </button>
      <div 
        ref={menuRef}
        className={`bg-gray-800 text-white min-h-screen fixed top-0 left-0 w-64 z-50 transition-transform duration-300 ease-in-out transform ${isVisible ? 'translate-x-0' : '-translate-x-full'}`}
        role="navigation"
        aria-hidden={!isVisible}
      >
        <div className="p-4 bg-gray-900 flex justify-between items-center">
          <h1 className="text-xl font-semibold">Gestión de Proyectos</h1>
        </div>
        {isVisible && (
          <nav className="gestores-menu mt-4">
            <ul className="space-y-2 p-4">
              {menuItems.map((item) => (
                <li 
                  key={item.path} 
                  className={`hover:bg-gray-700 p-2 rounded-md ${item.className || ""}`}
                >
                  <Link to={item.path} className="block text-lg">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </>
  );
};

export default GestoresMenu;
