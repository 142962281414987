import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// Rutas publicas
import Home from "./components/Home";
import Services from "./components/Services";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
// Seguridad
import Login from "./components/Login";
import Logout from "./components/Logout";
import ProtectedRoute from "./components/ProtectedRoute";
//  Contratistas Rutas
import ContratistasDocumentacion from "./components/contratistas/ContratistasDocumentacion";
import ContratistasEstadoObras from "./components/contratistas/ContratistasEstadoObras";
//  Gestores Rutas
import GestoresPanel from "./components/gestores/GestoresPanel";
import GestoresPlanificacion from "./components/gestores/GestoresPlanificacion";
import GestoresLista from "./components/gestores/GestoresLista";
import GestoresPresupuesto from "./components/gestores/GestoresPresupuesto";
import GestoresCalidad from "./components/gestores/GestoresCalidad";
import GestoresSeguridad from "./components/gestores/GestoresSeguridad";
// control de errores
import ErrorBoundary from "./components/ErrorBoundary";
//  Menus
import MainMenu from "./components/MainMenu";
import ContratistasMenu from "./components/ContratistasMenu";
import GestoresMenu from "./components/GestoresMenu";
import './index.css';



const App = () => {

    const location = useLocation();
    const renderMenu = () => {
      if (location.pathname.startsWith('/gestores/')) {
        return <GestoresMenu />;
      } else if (location.pathname.startsWith('/contratistas/')) {
        return <ContratistasMenu />;
      } else {
        return <MainMenu />;
      }
    };
  
    return (
      <div className="flex flex-col min-h-screen">
        <header>{renderMenu()}</header>


      <main className="flex-grow">
        <ErrorBoundary fallback={<p>Algo salió mal. Por favor, intenta de nuevo más tarde.</p>}>
          <Suspense fallback={<p>Cargando...</p>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/servicios" element={<Services />} />
              <Route path="/proyectos" element={<Projects />} />
              <Route path="/contacto" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />

              <Route path="/contratistas/documentacion" element={<ProtectedRoute><ContratistasDocumentacion /></ProtectedRoute>} />
              <Route path="/contratistas/estado-obras" element={<ProtectedRoute><ContratistasEstadoObras /></ProtectedRoute>} />

              <Route path="/gestores/panel" element={<ProtectedRoute><GestoresPanel /></ProtectedRoute>} />
              <Route path="/gestores/planificacion" element={<ProtectedRoute><GestoresPlanificacion /></ProtectedRoute>} />
              <Route path="/gestores/lista" element={<ProtectedRoute><GestoresLista /></ProtectedRoute>} />
              <Route path="/gestores/presupuesto" element={<ProtectedRoute><GestoresPresupuesto /></ProtectedRoute>} />
              <Route path="/gestores/calidad" element={<ProtectedRoute><GestoresCalidad /></ProtectedRoute>} />
              <Route path="/gestores/seguridad" element={<ProtectedRoute><GestoresSeguridad /></ProtectedRoute>} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </main>

      <footer className="bg-black bg-opacity-70 py-2 mt-auto">
        <div className="container mx-auto text-center text-white">
          <p className="text-xs leading-snug md:text-sm lg:text-base">
            Políticas: En Cover All Industrias nos comprometemos a ofrecer una
            atención personalizada, entregas rápidas, calidad, garantías y
            confianza en nuestros productos y servicios, mejorando continuamente
            la logística y atrayendo nuevas tecnologías para la satisfacción
            total del cliente.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default App;
