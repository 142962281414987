import React, { useState } from 'react';
import ProyectoForm from '../../components/proyectos/ProyectoForm';
import ProyectoList from '../../components/proyectos/ProyectoList';
import axios from 'axios';

const GestionProyectos = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleEdit = (project) => {
    setSelectedProject(project);
    setShowForm(true);
  };

  const handleDelete = (projectId) => {
    axios.delete(`/api/projects/${projectId}`)
      .then(() => {
        setSelectedProject(null);
        setShowForm(false);
        // Optionally refresh the project list here
      })
      .catch(error => console.error('Error deleting project', error));
  };

  const handleSave = () => {
    setSelectedProject(null);
    setShowForm(false);
    // Optionally refresh the project list here
  };

  const handleCancel = () => {
    setSelectedProject(null);
    setShowForm(false);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold my-4">Gestión de Proyectos</h1>
      {showForm && (
        <ProyectoForm
          project={selectedProject}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
      <ProyectoList
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <button
        onClick={() => {
          setSelectedProject(null);
          setShowForm(true);
        }}
        className="bg-green-500 text-white px-4 py-2 rounded mt-4"
      >
        Crear Nuevo Proyecto
      </button>
    </div>
  );
};

export default GestionProyectos;
