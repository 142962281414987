import React from 'react';

const TareaItem = ({ tarea, onEdit, onDelete }) => {
  return (
    <li className="p-4 border border-gray-300 rounded-lg shadow-sm bg-white">
      <h3 className="text-xl font-semibold mb-2">{tarea.nombre}</h3>
      <p className="text-gray-800 mb-1">{tarea.descripcion}</p>
      <p className="text-gray-600 mb-1">Fecha de Inicio: {new Date(tarea.fechaInicio).toLocaleDateString()}</p>
      <p className="text-gray-600 mb-1">Fecha de Finalización: {new Date(tarea.fechaFin).toLocaleDateString()}</p>
      <p className="text-gray-600 mb-1">Duración: {tarea.duracion} días</p>
      <p className="text-gray-600 mb-1">Dependencias: {tarea.dependencias.length > 0 ? tarea.dependencias.join(', ') : 'N/A'}</p>
      <p className="text-gray-600 mb-1">Recursos: {tarea.recursos.length > 0 ? tarea.recursos.join(', ') : 'N/A'}</p>
      <div className="mt-4 flex justify-end space-x-2">
        <button 
          onClick={onEdit} 
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-200"
        >
          Editar
        </button>
        <button 
          onClick={onDelete} 
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition duration-200"
        >
          Eliminar
        </button>
      </div>
    </li>
  );
};

export default TareaItem;
