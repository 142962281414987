import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");

  if (!token) {
    // No hay token, redirige a la página de inicio de sesión
    return <Navigate to="/login" />;
  }

  try {
    // Decodifica el token para verificar su validez
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Tiempo actual en segundos

    // Verifica si el token ha expirado
    if (decodedToken.exp < currentTime) {
      // Token expirado, redirige a la página de inicio de sesión
      localStorage.removeItem("authToken");
      return <Navigate to="/login" />;
    }

    // Token válido, renderiza los hijos
    return children;
  } catch (error) {
    // Token inválido, redirige a la página de inicio de sesión
    localStorage.removeItem("authToken");
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
