// GestoresSeguridad.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GestoresSeguridad = () => {
  const [securityData, setSecurityData] = useState({
    incidents: [],
    safetyPolicies: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/seguridad'); // Endpoint para obtener datos de seguridad
        setSecurityData(response.data);
      } catch (error) {
        console.error('Error fetching security data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Seguridad</h1>

      {/* Incidents Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Incidentes</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {securityData.incidents.length > 0 ? (
            securityData.incidents.map((incident, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">Incidente {index + 1}:</span> {incident.description}
                </div>
                <div>
                  <span className="font-medium">Fecha:</span> {new Date(incident.date).toLocaleDateString()}
                </div>
              </div>
            ))
          ) : (
            <div>No hay incidentes registrados.</div>
          )}
        </div>
      </section>

      {/* Safety Policies Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Políticas de Seguridad</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {securityData.safetyPolicies.length > 0 ? (
            securityData.safetyPolicies.map((policy, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">Política {index + 1}:</span> {policy.policyName}
                </div>
                <div>
                  <span className="font-medium">Descripción:</span> {policy.description}
                </div>
              </div>
            ))
          ) : (
            <div>No hay políticas de seguridad disponibles.</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default GestoresSeguridad;
