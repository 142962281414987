import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TareaForm = ({ tarea, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: '',
    fechaInicio: '',
    fechaFin: '',
    duracion: 0,
    dependencias: [],
    recursos: [],
    proyectoId: '' // Agregado para el ID del proyecto
  });

  const [listas, setListas] = useState({
    dependencias: [],
    recursos: []
  });

  const [proyectos, setProyectos] = useState([]);

  useEffect(() => {
    if (tarea) {
      setFormData({ ...tarea, dependencias: tarea.dependencias.map(dep => dep._id), recursos: tarea.recursos.map(rec => rec._id) });
    }
    // Fetch lists for dependencias and recursos
    axios.get('http://localhost:5000/api/list')
      .then(response => {
        const dependencias = response.data.filter(item => item.tipo === 'dependencia');
        const recursos = response.data.filter(item => item.tipo === 'recurso');
        setListas({ dependencias, recursos });
      })
      .catch(error => console.error('Error fetching lists', error));

    // Fetch projects
    axios.get('http://localhost:5000/api/projects')
      .then(response => setProyectos(response.data))
      .catch(error => console.error('Error fetching projects', error));
  }, [tarea]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleArrayChange = (e, name) => {
    const { options } = e.target;
    const selectedValues = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);
    setFormData(prevData => ({ ...prevData, [name]: selectedValues }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = tarea ? `http://localhost:5000/api/tasks/${tarea._id}` : 'http://localhost:5000/api/tasks';
    const method = tarea ? 'put' : 'post';
    axios[method](url, formData) // Ensure proyectoId is included
      .then(response => onSave(response.data))
      .catch(error => console.error('Error saving task', error));
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 max-w-lg mx-auto">
      <div className="mb-4">
        <label className="block text-gray-700">Nombre</label>
        <input
          type="text"
          name="nombre"
          value={formData.nombre}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Descripción</label>
        <textarea
          name="descripcion"
          value={formData.descripcion}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Fecha de Inicio</label>
        <input
          type="date"
          name="fechaInicio"
          value={formData.fechaInicio}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Fecha de Finalización</label>
        <input
          type="date"
          name="fechaFin"
          value={formData.fechaFin}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Duración (días)</label>
        <input
          type="number"
          name="duracion"
          value={formData.duracion}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Dependencias</label>
        <select
          name="dependencias"
          multiple
          value={formData.dependencias}
          onChange={(e) => handleArrayChange(e, 'dependencias')}
          className="w-full p-2 border border-gray-300 rounded"
        >
          {listas.dependencias.map(dep => (
            <option key={dep._id} value={dep._id}>
              {dep.nombre}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Recursos</label>
        <select
          name="recursos"
          multiple
          value={formData.recursos}
          onChange={(e) => handleArrayChange(e, 'recursos')}
          className="w-full p-2 border border-gray-300 rounded"
        >
          {listas.recursos.map(rec => (
            <option key={rec._id} value={rec._id}>
              {rec.nombre}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Proyecto</label>
        <select
          name="proyectoId"
          value={formData.proyectoId}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="" disabled>Selecciona un proyecto</option>
          {proyectos.map(proy => (
            <option key={proy._id} value={proy._id}>
              {proy.nombre}
            </option>
          ))}
        </select>
      </div>
      <div className="flex justify-end">
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Guardar</button>
        <button type="button" onClick={onCancel} className="ml-2 bg-gray-500 text-white px-4 py-2 rounded">Cancelar</button>
      </div>
    </form>
  );
};

export default TareaForm;
