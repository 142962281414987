// GestoresPresupuesto.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GestoresPresupuesto = () => {
  const [budgetData, setBudgetData] = useState({
    budgets: [],
    expenses: [],
    costComparison: {},
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/presupuesto'); // Endpoint para obtener datos de presupuesto
        setBudgetData(response.data);
      } catch (error) {
        console.error('Error fetching budget data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Presupuesto</h1>

      {/* Budgets Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Presupuestos</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {budgetData.budgets.length > 0 ? (
            budgetData.budgets.map((budget, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">Presupuesto {index + 1}:</span> {budget.amount}
                </div>
                <div>
                  <span className="font-medium">Descripción:</span> {budget.description}
                </div>
              </div>
            ))
          ) : (
            <div>No hay presupuestos disponibles.</div>
          )}
        </div>
      </section>

      {/* Expenses Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Gastos</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {budgetData.expenses.length > 0 ? (
            budgetData.expenses.map((expense, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">Gasto {index + 1}:</span> {expense.amount}
                </div>
                <div>
                  <span className="font-medium">Categoría:</span> {expense.category}
                </div>
              </div>
            ))
          ) : (
            <div>No hay gastos registrados.</div>
          )}
        </div>
      </section>

      {/* Cost Comparison Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Comparación de Costos</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          <div>
            <span className="font-medium">Costos Estimados:</span> {budgetData.costComparison.estimatedCosts}
          </div>
          <div>
            <span className="font-medium">Costos Reales:</span> {budgetData.costComparison.actualCosts}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GestoresPresupuesto;
