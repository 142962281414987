import React from 'react';
import TareaList from '../../components/planificacion/TareaList';

const Planificacion = ({ proyectoId }) => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Planificación del Proyecto</h1>
      <TareaList proyectoId={proyectoId} />
    </div>
  );
};

export default Planificacion;
