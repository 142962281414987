import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ContratistasMenu = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleMenu = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="relative">
            <button 
                onClick={toggleMenu} 
                className="bg-blue-600 text-white py-2 px-4 rounded focus:outline-none md:hidden"
            >
                {isVisible ? 'Ocultar Menú' : 'Mostrar Menú'}
            </button>

            {/* Menú Visible en Pantallas Grandes */}
            <nav className="hidden md:block bg-gray-800 text-white w-64 absolute top-full left-0 mt-2 rounded shadow-lg md:static md:w-auto md:bg-transparent">
                <ul className="flex flex-col md:flex-row p-4 md:p-0 md:space-x-4">
                    <li>
                        <Link to="/contratistas/documentacion" className="hover:bg-gray-700 p-2 rounded md:hover:bg-transparent">
                            Documentación
                        </Link>
                    </li>
                    <li>
                        <Link to="/contratistas/estado-obras" className="hover:bg-gray-700 p-2 rounded md:hover:bg-transparent">
                            Estado de Obras
                        </Link>
                    </li>
                    <li>
                        <Link to="/contratistas/pagos" className="hover:bg-gray-700 p-2 rounded md:hover:bg-transparent">
                            Pagos
                        </Link>
                    </li>
                    <li>
                        <Link to="/contratistas/reportes" className="hover:bg-gray-700 p-2 rounded md:hover:bg-transparent">
                            Reportes
                        </Link>
                    </li>
                    <li>
                        <Link to="/logout" className="hover:bg-gray-700 p-2 rounded md:hover:bg-transparent">
                            Cerrar Sesión
                        </Link>
                    </li>
                </ul>
            </nav>

            {/* Menú Visible en Pantallas Pequeñas */}
            {isVisible && (
                <nav className="bg-gray-800 text-white w-full absolute top-full left-0 mt-2 rounded shadow-lg md:hidden">
                    <ul className="flex flex-col p-4 space-y-2">
                        <li>
                            <Link to="/contratistas/documentacion" className="hover:bg-gray-700 p-2 rounded">
                                Documentación
                            </Link>
                        </li>
                        <li>
                            <Link to="/contratistas/estado-obras" className="hover:bg-gray-700 p-2 rounded">
                                Estado de Obras
                            </Link>
                        </li>
                        <li>
                            <Link to="/contratistas/pagos" className="hover:bg-gray-700 p-2 rounded">
                                Pagos
                            </Link>
                        </li>
                        <li>
                            <Link to="/contratistas/reportes" className="hover:bg-gray-700 p-2 rounded">
                                Reportes
                            </Link>
                        </li>
                        <li>
                            <Link to="/logout" className="hover:bg-gray-700 p-2 rounded">
                                Cerrar Sesión
                            </Link>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default ContratistasMenu;
