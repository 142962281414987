import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Eliminar el token de localStorage
        localStorage.removeItem('userRole');
        navigate('/login'); // Redirigir a la página de inicio de sesión
    };

    return (
        <div>
            <h1>Logout</h1>
            <button onClick={handleLogout}>Cerrar Sesión</button>
        </div>
    );
};

export default Logout;
