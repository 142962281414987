// GestoresCalidad.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GestoresCalidad = () => {
  const [qualityData, setQualityData] = useState({
    qualityControls: [],
    audits: [],
    nonConformities: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/calidad'); // Endpoint para obtener datos de calidad
        setQualityData(response.data);
      } catch (error) {
        console.error('Error fetching quality data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Gestión de Calidad</h1>

      {/* Quality Controls Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Controles de Calidad</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {qualityData.qualityControls.length > 0 ? (
            qualityData.qualityControls.map((control, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">Control {index + 1}:</span> {control.controlName}
                </div>
                <div>
                  <span className="font-medium">Descripción:</span> {control.description}
                </div>
              </div>
            ))
          ) : (
            <div>No hay controles de calidad registrados.</div>
          )}
        </div>
      </section>

      {/* Audits Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Auditorías</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {qualityData.audits.length > 0 ? (
            qualityData.audits.map((audit, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">Auditoría {index + 1}:</span> {audit.auditName}
                </div>
                <div>
                  <span className="font-medium">Fecha:</span> {new Date(audit.date).toLocaleDateString()}
                </div>
              </div>
            ))
          ) : (
            <div>No hay auditorías registradas.</div>
          )}
        </div>
      </section>

      {/* Non-Conformities Section */}
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">No Conformidades</h2>
        <div className="bg-white shadow-md rounded-lg p-4">
          {qualityData.nonConformities.length > 0 ? (
            qualityData.nonConformities.map((nc, index) => (
              <div key={index} className="mb-4">
                <div>
                  <span className="font-medium">No Conformidad {index + 1}:</span> {nc.description}
                </div>
                <div>
                  <span className="font-medium">Medida Correctiva:</span> {nc.correctiveAction}
                </div>
              </div>
            ))
          ) : (
            <div>No hay no conformidades registradas.</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default GestoresCalidad;
