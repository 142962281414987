import React from 'react';

const ProyectoItem = ({ project, onEdit, onDelete }) => {
  return (
    <li className="mb-4 p-4 border border-gray-300 rounded flex justify-between items-center">
      <div>
        <h3 className="text-xl font-bold">{project.name}</h3>
        <p>{project.description}</p>
        <p className="text-gray-600">Estado: {project.status}</p>
      </div>
      <div>
        <button onClick={() => onEdit(project)} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Editar</button>
        <button onClick={() => onDelete(project._id)} className="bg-red-500 text-white px-4 py-2 rounded">Eliminar</button>
      </div>
    </li>
  );
};

export default ProyectoItem;
