import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProyectoForm = ({ project, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    status: 'Activo',
  });

  useEffect(() => {
    if (project) {
      setFormData(project);
    }
  }, [project]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (project) {
      // Editar proyecto
      axios.put(`http://localhost:5000/api/projects/${project._id}`, formData)
        .then(response => onSave(response.data))
        .catch(error => console.error('Error updating project', error));
    } else {
      // Crear proyecto
      axios.post('http://localhost:5000/api/projects', formData)
        .then(response => onSave(response.data))
        .catch(error => console.error('Error creating project', error));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 max-w-lg mx-auto">
      <div className="mb-4">
        <label className="block text-gray-700">Nombre</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Descripción</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Fecha de Inicio</label>
        <input
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Fecha de Finalización</label>
        <input
          type="date"
          name="endDate"
          value={formData.endDate}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Estado</label>
        <select
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="Activo">Activo</option>
          <option value="En Pausa">En Pausa</option>
          <option value="Completado">Completado</option>
        </select>
      </div>
      <div className="flex justify-end">
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Guardar</button>
        <button type="button" onClick={onCancel} className="ml-2 bg-gray-500 text-white px-4 py-2 rounded">Cancelar</button>
      </div>
    </form>
  );
};

export default ProyectoForm;
