import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ListasGestion = () => {
  const [listas, setListas] = useState([]);
  const [formData, setFormData] = useState({ nombre: '', tipo: 'dependencia' });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    axios.get('http://localhost:5000/api/list')
      .then(response => setListas(response.data))
      .catch(error => console.error('Error fetching lists', error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = editingId ? `http://localhost:5000/api/list/${editingId}` : 'http://localhost:5000/api/list';
    const method = editingId ? 'put' : 'post';

    axios[method](url, formData)
      .then(response => {
        if (editingId) {
          setListas(listas.map(item => (item._id === response.data._id ? response.data : item)));
        } else {
          setListas([...listas, response.data]);
        }
        setFormData({ nombre: '', tipo: 'dependencia' });
        setEditingId(null);
      })
      .catch(error => console.error('Error saving list', error));
  };

  const handleEdit = (id) => {
    const item = listas.find(l => l._id === id);
    setFormData({ nombre: item.nombre, tipo: item.tipo });
    setEditingId(id);
  };

  const handleDelete = (id) => {
    axios.delete(`http://localhost:5000/api/list/${id}`)
      .then(() => setListas(listas.filter(l => l._id !== id)))
      .catch(error => console.error('Error deleting list', error));
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Gestión de Dependencias y Recursos</h1>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="mb-4">
          <label className="block text-gray-700">Nombre</label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Tipo</label>
          <select
            name="tipo"
            value={formData.tipo}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="dependencia">Dependencia</option>
            <option value="recurso">Recurso</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            {editingId ? 'Actualizar' : 'Crear'}
          </button>
        </div>
      </form>
      <ul>
        {listas.map(item => (
          <li key={item._id} className="p-4 border border-gray-300 rounded mb-2 flex justify-between items-center">
            <span>{item.nombre} ({item.tipo})</span>
            <div>
              <button onClick={() => handleEdit(item._id)} className="bg-blue-500 text-white px-2 py-1 rounded mr-2">
                Editar
              </button>
              <button onClick={() => handleDelete(item._id)} className="bg-red-500 text-white px-2 py-1 rounded">
                Eliminar
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListasGestion;
