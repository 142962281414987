import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TareaItem from './TareaItem';
import TareaForm from './TareaForm';

const TareaList = ({ proyectoId }) => {
  const [tareas, setTareas] = useState([]);
  const [selectedTarea, setSelectedTarea] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`http://localhost:5000/api/tasks?proyectoId=${proyectoId}`)
      .then(response => {
        setTareas(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching tasks');
        setLoading(false);
      });
  }, [proyectoId]);

  const handleEdit = (tarea) => {
    setSelectedTarea(tarea);
    setIsFormVisible(true);
  };

  const handleDelete = (id) => {
    axios.delete(`http://localhost:5000/api/tasks/${id}`)
      .then(() => {
        setTareas(tareas.filter(t => t._id !== id));
      })
      .catch(error => console.error('Error deleting task', error));
  };

  const handleSave = (tarea) => {
    setTareas(tareas.map(t => (t._id === tarea._id ? tarea : t)));
    setIsFormVisible(false);
    setSelectedTarea(null);
  };

  const handleCancel = () => {
    setIsFormVisible(false);
    setSelectedTarea(null);
  };

  if (loading) return <p>Cargando tareas...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-4">
      <button 
        onClick={() => {
          setSelectedTarea(null);
          setIsFormVisible(true);
        }} 
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg mb-4 transition duration-200"
      >
        Nueva Tarea
      </button>
      {isFormVisible && (
        <TareaForm 
          tarea={selectedTarea} 
          onSave={handleSave} 
          onCancel={handleCancel} 
          proyectoId={proyectoId}
        />
      )}
      <ul className="space-y-4">
        {tareas.map(tarea => (
          <TareaItem 
            key={tarea._id} 
            tarea={tarea} 
            onEdit={() => handleEdit(tarea)} 
            onDelete={() => handleDelete(tarea._id)} 
          />
        ))}
      </ul>
    </div>
  );
};

export default TareaList;
